<template>
  <div class="table-wrap">
    <div class="component-tit">
      <span>{{ taskInfo.taskName }}</span>
      <a-button type="primary" @click="back">
        {{ $t("go_back") }}
        <!-- 返回 -->
      </a-button>
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 完成 -->
      <ListFilter
        v-if="completeOptions.length"
        only
        :name="$t('BN_Comm_Completed')"
        :dataSource="completeOptions"
        @onChange="switchComplete"
        :allObj="false"
      />
      <!-- 通过 -->
      <ListFilter
        only
        :name="$t('CM_Pass')"
        :dataSource="qualifierOptions"
        @onChange="switchQualifier"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.keyword"
              :placeholder="$t('teaching.please_enter_name_account')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch"
            />
            <!-- 请输入姓名/账号 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <a-table
      :scroll="{ x: 884, y: 930 }"
      :columns="columns"
      :data-source="state.dataSource"
      :rowKey="(record, index) => index"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #realName="{ record }">
        <OpenData type="userName" :openid="record.realName" />
      </template>
      <template #departmentName="{ record }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template
              v-for="(str, strIndex) in record.department.split('/')"
              :key="strIndex"
            >
              <OpenData type="departmentName" :openid="str" />
              <span v-if="strIndex + 1 < record.department.split('/').length"
                >/</span
              >
            </template>
          </template>
          <OpenData type="departmentName" :openid="record.departmentName" />
        </a-tooltip>
      </template>
      <template #ispass="{ record }">
        <span v-if="record.qualifier == 1" style="color: #44b44f">{{
          $t("Pub_Passed")
        }}</span>
        <span v-else style="color: #e8673e">{{ $t("Pub_Notpassed") }}</span>
        <!-- "已通过" : "未通过" -->
      </template>
      <template #percentage="{ text }">
        {{ toPercent(text) }}
      </template>
      <template #formatSeconds="{ text }">
        {{ formatSeconds(text) }}
      </template>
      <template #progress="{ text }">{{ text }}%</template>
      <template #studyProgress="{ record }">
        <span v-if="record.complete == 1">{{
          $t("teaching.not_arranged")
        }}</span>
        <!-- 未安排 -->
        <span v-else-if="record.complete == 2">{{ $t("CM_Completed") }}</span>
        <!-- 已完成 -->
        <span v-else-if="record.complete == 3">{{
          $t("teaching.overdue")
        }}</span>
        <!-- 逾期 -->
      </template>
    </a-table>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { formatSeconds, toPercent } from "@/utils/tools";
import { teachManageTrackMember } from "@/api/map";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    ListFilter,
    OpenData,
  },
  props: {
    taskInfo: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const stateData = reactive({
      completeOptions: [],
      qualifierOptions: [
        { name: $t("Pub_Passed"), id: 1 }, // 已通过
        { name: $t("Pub_Notpassed"), id: 2 }, // 未通过
      ],
      columns: [
        {
          title: $t("teaching.table.name"),
          // title: "姓名",
          width: 120,
          dataIndex: "realName",
          slots: { customRender: "realName" },
          ellipsis: true,
        },
        {
          title: $t("teaching.table.account"),
          // title: "账号",
          width: 120,
          dataIndex: "account",
          ellipsis: true,
        },
        {
          title: $t("teaching.table.department"),
          // title: "部门",
          width: 160,
          slots: { customRender: "departmentName" },
          ellipsis: true,
        },
        {
          title: $t("teaching.table.position"),
          // title: "岗位",
          width: 160,
          dataIndex: "position",
          ellipsis: true,
        },
        {
          title: $t("teaching.table.whether_to_pass"),
          // title: "是否通过",
          width: 200,
          dataIndex: "qualifier",
          slots: { customRender: "ispass" },
        },
        {
          title: $t("teaching.table.learning_progress"),
          // title: "学习进度",
          width: 200,
          dataIndex: "progress",
          slots: { customRender: "progress" },
        },
        {
          title: $t("teaching.table.study_completed"),
          // title: "学习完成",
          width: 200,
          dataIndex: "complete",
          slots: { customRender: "studyProgress" },
        },
        {
          title: $t("teaching.table.pass_num"),
          // title: "及格场次",
          width: 200,
          dataIndex: "exams",
        },
        {
          title: $t("teaching.table.online_study_time"),
          // title: "线上学习时长",
          width: 200,
          dataIndex: "learnTime",
          slots: { customRender: "formatSeconds" },
        },
        {
          title: $t("teaching.table.exam_time"),
          // title: "考试用时",
          width: 200,
          dataIndex: "examTime",
          slots: { customRender: "formatSeconds" },
        },
      ],
    });

    let stages = props.taskInfo.stages;
    let stagesOv = {};
    for (let i = 0; i < stages.length; i++) {
      stagesOv[stages[i].stageId] = stages[i];
      let details = stages[i].details || [];
      for (let j = 0; j < details.length; j++) {
        stagesOv[stages[i].stageId][details[j].detailId] = details[j];
      }
    }
    for (let key in stagesOv) {
      stagesOv[key].details = stagesOv[key].details || [];
      stateData.columns.push({
        title: stagesOv[key].stageName,
        dataIndex: "stage_" + stagesOv[key].stageId,
        default: true,
        hide: true,
        children: [
          {
            title: $t("teaching.table.stage_progress"),
            // title: "阶段进度",
            width: 100,
            dataIndex: "stage",
            customRender: ({ record }) => {
              record.studyDetails = record.studyDetails || [];
              let n = 0;
              for (let i = 0; i < record.studyDetails.length; i++) {
                if (stagesOv[key][record.studyDetails[i].detailId]) {
                  n += record.studyDetails[i].progress;
                }
              }
              return Math.floor(n / stagesOv[key].details.length) + "%";
            },
          },
        ].concat(
          stagesOv[key].details.map((item) => {
            return {
              title: item.detailName,
              width: 200,
              dataIndex: "detail",
              customRender: ({ record }) => {
                record.studyDetails = record.studyDetails || [];
                for (let i = 0; i < record.studyDetails.length; i++) {
                  if (item.detailId == record.studyDetails[i].detailId) {
                    if (item.resourceType == 3) {
                      if (record.studyDetails[i].progress != 0) {
                        return (
                          record.studyDetails[i].examScore +
                          $t("Lab_Teacher_Fen") +
                          (record.studyDetails[i].qualifier == 1
                            ? `(${`(${$t("Pub_Passed")})`})`
                            : `(${$t("Pub_Notpassed")})`)
                        );
                        // 已通过
                        // 不通过
                      } else {
                        return $t("XB_Exam_PendingApproval");
                        // 待批阅
                      }
                    } else {
                      return record.studyDetails[i].progress + "%";
                    }
                  }
                }
              },
            };
          })
        ),
      });
    }

    let queryParam = reactive({
      keyword: "",
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return teachManageTrackMember(props.taskInfo.taskId, form).then((res) => {
        if (!stateData.completeOptions.length) {
          stateData.completeOptions = [
            { name: `${$t("CM_All")}(${res.data.totals})`, id: 0 },
            // 全部
            {
              name: `${$t("XB_UnderWay")}(${res.data.complete[2] || 0})`,
              id: 2,
            },
            // 进行中
            {
              name: `${$t("BN_Comm_Completed")}(${res.data.complete[1] || 0})`,
              id: 1,
            },
            // 完成
            {
              name: `${$t("teaching.overdue")}(${res.data.complete[3] || 0})`,
              id: 3,
            },
            // 逾期
          ];
        }
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 30,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const back = () => {
      emit("back");
    };

    const switchComplete = (arr) => {
      queryParam.complete = arr[0];
      handleSearch();
    };

    const switchQualifier = (arr) => {
      queryParam.qualifier = arr[0];
      handleSearch();
    };

    return {
      formatSeconds,
      toPercent,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      back,
      switchComplete,
      switchQualifier,
    };
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
    .mixinFlex(space-between; center);
  }
  .filter-wrap {
    padding-bottom: 12px;
    margin-bottom: 16px;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  ::v-deep(.ant-table-thead > tr > th),
  ::v-deep(.ant-table-tbody > tr > td) {
    vertical-align: middle;
  }
}
</style>
